import howToBuyAlternate from '@/assets/pngs/how-to-buy-alternate.png'
import howToBuy from '@/assets/pngs/how-to-buy.png'
import logo from '@/assets/pngs/logo.png'
import memeOne from '@/assets/pngs/meme-one.png'
import memeThree from '@/assets/pngs/meme-three.png'
import memeTwo from '@/assets/pngs/meme-two.png'
import money from '@/assets/pngs/money.png'
import tokenomicFourLong from '@/assets/pngs/tokenomic-four-long.png'
import tokenomicFour from '@/assets/pngs/tokenomic-four.png'
import tokenomicOne from '@/assets/pngs/tokenomic-one.png'
import tokenomicThree from '@/assets/pngs/tokenomic-three.png'
import tokenomicTwo from '@/assets/pngs/tokenomic-two.png'

export const HowToBuy = howToBuy
export const HowToBuyAlternate = howToBuyAlternate
export const ADLogo = logo
export const MemeOne = memeOne
export const MemeTwo = memeTwo
export const MemeThree = memeThree
export const MoneyIcon = money
export const TokenomicOne = tokenomicOne
export const TokenomicTwo = tokenomicTwo
export const TokenomicThree = tokenomicThree
export const TokenomicFour = tokenomicFour
export const TokenomicFourLong = tokenomicFourLong
