import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import { AppLayout } from '@/components/layout'
import { cn } from '@/utils/cn'
import AOS from 'aos'

import {
	HowToBuy,
	HowToBuyAlternate,
	MemeTwo,
	MoneyIcon,
	TokenomicFour,
	TokenomicFourLong,
	TokenomicOne,
	TokenomicThree,
	TokenomicTwo
} from '@/assets/icons'
import ADCLogo from '@/assets/svgs/adc-logo.svg?react'
import BlueLeftCircle from '@/assets/svgs/blue-left-circle.svg?react'
import BlueRightCircle from '@/assets/svgs/blue-right-circle.svg?react'
import BuyButton from '@/assets/svgs/buy-button.svg?react'
import DexScreener from '@/assets/svgs/dex-screener.svg?react'
import Money from '@/assets/svgs/money.svg?react'
import SmallBuyButton from '@/assets/svgs/small-buy-button.svg?react'
import Twitter from '@/assets/svgs/twitter.svg?react'
import Youtube from '@/assets/svgs/youtube.svg?react'
import video from '@/assets/video/video.mp4'

import 'aos/dist/aos.css'

export const Home = () => {
	const tokenRef = useRef<HTMLDivElement>(null)
	const buyRef = useRef<HTMLDivElement>(null)
	const socialRef = useRef<HTMLDivElement>(null)

	const navOptions = [
		{
			name: 'Tokenomics',
			selected: true,
			ref: tokenRef
		},
		{
			name: 'How to Buy',
			selected: false,
			ref: buyRef
		},
		{
			name: 'Socials',
			selected: false,
			ref: socialRef
		}
	]

	useEffect(() => {
		AOS.init({
			once: true,
			mirror: false,
			duration: 1000,
			disable: 'mobile',
			anchorPlacement: 'top-bottom'
		})
	}, [])

	return (
		<AppLayout>
			<header className="pb-5 max-md:hidden overflow-hidden relative pt-8 min-h-[875px] bg-header bg-cover bg-bottom bg-no-repeat">
				<div className="container px-12 font-poppins mx-auto grid grid-cols-3 items-center">
					<ADCLogo className="z-10 relative h-[92px]" />
					<nav
						style={{ boxShadow: '0px 4px 24px 0px #6B72801F' }}
						className="flex z-10 rounded-full w-fit items-center gap-3 px-6 bg-black">
						{navOptions.map(option => (
							<button
								className={cn(
									'font-medium py-3 px-[18px] whitespace-nowrap',
									option.selected ? 'text-primary' : 'text-white'
								)}
								onClick={() =>
									option.ref.current?.scrollIntoView({
										behavior: 'smooth'
									})
								}
								key={option.name}>
								{option.name}
							</button>
						))}
					</nav>
				</div>
				<div className="container px-24 mt-32 mx-auto grid grid-cols-2">
					<div className="flex z-10 flex-col items-start gap-y-8">
						<h1 className="font-bangers lg:whitespace-nowrap text-7xl text-black uppercase">
							Angry Dad Coin <span className="text-darkBlue">($ADC)</span>
						</h1>
						<div className="flex flex-col uppercase text-black text-3xl font-bold">
							<h4>
								Who doesn't know it? The legendary angry dad who knows everything better than you!
								Now is the time to prove him wrong!
							</h4>
						</div>
						<Link to="https://raydium.io" target="__blank">
							<BuyButton className="h-32 -ml-20 cursor-pointer" />
						</Link>
					</div>
				</div>
				<Money className="absolute top-0" />
			</header>
			<header className="md:hidden overflow-hidden relative min-h-[880px] bg-cover bg-bottom bg-mobileHeader bg-no-repeat">
				<div className="px-5 py-4">
					<ADCLogo className="z-10 relative max-md:size-[48px]" />
				</div>
				<div className="px-5 mt-6 flex flex-col items-center gap-8">
					<h1 className="uppercase sm:whitespace-nowrap font-bangers text-black text-5xl">
						Angry Dad Coin <span className="text-darkBlue">($ADC)</span>
					</h1>
					<div className="flex items-center flex-col uppercase font-bold text-black">
						<h3>
							Who doesn't know it? The legendary dad who knows everything better than you! Now is
							the time to prove him wrong!
						</h3>
					</div>
					<Link className="z-50" to="https://raydium.io" target="__blank">
						<SmallBuyButton className="cursor-pointer -mt-6" />
					</Link>
				</div>
				<img src={MoneyIcon} className="absolute inset-0 z-10 self-stretch object-cover" />
			</header>
			<ADCLogo className="mx-auto z-10 relative max-md:w-[135px] md:-mt-48 h-36" />
			<div
				data-aos="fade-up"
				ref={tokenRef}
				className="flex px-5 flex-col items-center mt-6 md:mt-14 relative gap-y-6 md:gap-y-12">
				<div className="flex flex-col items-center gap-y-4">
					<div className="grid md:container md:mx-auto md:grid-cols-2 grid-cols-1 items-center">
						<div className="flex flex-col max-md:items-center gap-y-4 md:pl-10">
							<h2 className="uppercase font-hussan text-2xl md:text-6xl text-darkBlue">
								Angry Dad ($ADC)
							</h2>
							<h4 className="font-bangers uppercase text-black text-2xl md:text-6xl">
								Dive into a Classic Father & Son Duo
							</h4>
						</div>
						<img src={MemeTwo} className="max-md:hidden ml-auto" />
					</div>
					<p className="text-center max-md:text-sm w-3/4 -mt-1">
						Are you also affected by Angry Dad Syndrome? You're not the only one! “As long as you
						live under my roof, you do what I tell you!” That certainly doesn't include trading meme
						coins! We are addressing a general social issue that is omnipresent in our society.{' '}
						<b>New generation vs. “old” generation - who is really right?</b> Are you ready to step
						into the ring and prove that the “older” generation is not always right?
					</p>
					<p className="text-center max-md:text-lg text-xl w-3/4">
						The first meme coin with a real connection to reality - join the world's largest
						community and let's usher in a new era together! <b>Buy $ADC now</b>.
					</p>
					<video controls>
						<source src={video} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					<h3 className="uppercase text-black mt-8 font-bangers text-3xl md:text-7xl">
						Tokenomics
					</h3>
					<BlueLeftCircle className="absolute -z-10 inset-y-0 -left-[102px]" />
					<BlueRightCircle className="absolute max-md:-z-10 inset-y-0 -translate-y-[600px] right-0" />
				</div>
				<div className="self-stretch md:hidden justify-center max-md:grid max-md:grid-cols-3 items-center gap-6 md:gap-12">
					<img src={TokenomicOne} alt="tokenomic-one" />
					<img src={TokenomicTwo} alt="tokenomic-two" />
					<img src={TokenomicThree} alt="tokenomic-three" />
				</div>
				<img src={TokenomicFour} alt="tokenomic-four" className="md:hidden w-full" />
				<img src={MemeTwo} className="md:hidden" />

				<img src={TokenomicFourLong} alt="tokenomic-four" className="max-md:hidden" />

				<div className="self-stretch max-md:hidden justify-center max-md:grid-cols-2 md:flex items-center gap-6 md:gap-12">
					<img src={TokenomicOne} alt="tokenomic-one" />
					<img src={TokenomicTwo} alt="tokenomic-two" />
					<img src={TokenomicThree} alt="tokenomic-three" />
				</div>
			</div>
			<div
				data-aos="zoom-in"
				ref={buyRef}
				className="md:container mt-6 md:mx-auto md:px-10 md:mt-14">
				<img src={HowToBuy} className="max-md:hidden" />
				<img src={HowToBuyAlternate} className="md:hidden" />
			</div>
			<div className="flex flex-col items-center gap-y-10 mt-6 md:gap-y-20 md:mt-14">
				<div ref={socialRef} className="flex flex-col items-center gap-y-6 md:gap-y-10">
					<h1 className="font-bangers text-black text-3xl md:text-7xl">
						Join The angry dad community
					</h1>
					<h4 className="text-black font-bold text-xl md:text-3xl">
						Let’s prove all of your dad’s wrong!
					</h4>
				</div>
				<div data-aos="flip-down" className="flex gap-x-5 md:gap-x-20 items-center">
					<Link to="https://x.com/AngryDadCoinSol" target="__blank">
						<Twitter className="max-md:size-[77px] cursor-pointer" />
					</Link>
					<Link to="https://t.me/angrydadcoin" target="__blank">
						<Youtube className="max-md:size-[77px] cursor-pointer" />
					</Link>
					<Link
						to="https://dexscreener.com/solana/2puugkqcphaerazyq3je61eom3xwlckgzepxwqnvs3dn"
						target="__blank">
						<DexScreener className="max-md:size-[77px] cursor-pointer" />
					</Link>
				</div>
				<div className="md:mt-14 mt-6 bg-black p-10">
					<p className="text-white text-center font-medium">
						Disclosure: By Purchasing $ADC, You agree that you are not purchasing a security or
						purchasing a security or investment contract and you agree to hold the team harmless and
						not liable for any loses or taxes you may incur. Although angry dad is a community
						driven defi ecosystem and not a registered digital currency, always make sure that you
						are in compliance with local laws and regulations before you make any purchase.
					</p>
				</div>
			</div>
		</AppLayout>
	)
}
